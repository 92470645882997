// src/pages/auth0_callback
 
import React, { useEffect } from "react";
 
import { useAuth } from "react-use-auth";
 
const Auth0CallbackPage = () => {
    const { handleAuthentication } = useAuth();
    useEffect(() => {
        handleAuthentication({ postLoginRoute: "/account" });
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
 
    return (
        <>
            <p>
                Loading...
            </p>
        </>
    );
};
 
export default Auth0CallbackPage;